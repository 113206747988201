import React from "react";
import { string, bool, number } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../../Base/HeadingBuilder";
import AppetizeImage from "../../../../components/ImageQuerys/AppetizeImages";
import spot from "../../../../images/svg/spoton-spot-white.svg";
import useStartMotion from "../../../../hooks/use-start-motion";

const variants = {
  hidden: { opacity: 0, y: 150 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const SecondaryHeroTitle = ({
  className,
  title,
  subtext,
  imageName,
  mobileImageName,
  withSmallImage,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      data-cy="secondary-hero-title"
      className={`relative
        max-w-1440 w-full
        px-0 md:px-4 lg:px-10
        ${className}
      `}
      ref={ref}
    >
      <div
        className={`
          relative w-full
          bg-black flex justify-center items-center rounded-large
          pt-36
          ${withSmallImage ? "pb-32 md:pb-60" : "pb-64"}
          lg:pt-40 lg:pb-72
      `}
      >
        <img
          src={spot}
          alt="logo"
          className="absolute top-20 left-4 md:left-10 lg:left-20 2xl:left-20"
        />
        <motion.div
          // className="w-full"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={fadeInVariants}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <Heading
            level={2}
            injectionType={1}
            className="text-white mx-auto text-center max-w-90-percent md:max-w-70-percent"
            withBlueSymbol
          >
            {title}
          </Heading>

          {subtext && (
            <p className="text-black-600 text-p text-center mt-6 lg:mt-8 px-4 md:px-28 lg:px-40 xl:px-60">
              {subtext}
            </p>
          )}
        </motion.div>
      </div>

      <motion.div
        className={`
          rounded-large max-w-1120 mx-auto
          ${withSmallImage ? "-mt-28" : "-mt-52"}
          md:-mt-48
          xl:-mt-56
          px-4 md:px-10 xl:px-0
        `}
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        {mobileImageName && (
          <AppetizeImage
            className="rounded-large w-full md:hidden"
            imageName={mobileImageName}
          />
        )}

        <AppetizeImage
          className="sht-image rounded-large w-full hidden md:block"
          imageName={imageName}
        />
      </motion.div>
    </section>
  );
};

SecondaryHeroTitle.propTypes = {
  className: string,
  title: string,
  subtext: string,
  imageName: string,
  mobileImageName: string,
  withSmallImage: bool,
  animations: bool,
};

SecondaryHeroTitle.defaultProps = {
  className: "",
  title: "",
  subtext: "",
  imageName: "",
  mobileImageName: null,
  withSmallImage: false,
  animations: true,
};

export default SecondaryHeroTitle;
